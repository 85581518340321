import React from "react";
import "../assets/css/App.css";
import "../assets/css/index.css";
import { Button, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Img from "gatsby-image";
import SEO from "../components/SEO";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

const StyledButton = styled(Button)`
  & {
    margin: 0;
  }
`;

export default ({ data }) => (
  <Layout>
    <SEO
      title="Plant Room Installation at Marble House"
      description="BBIS Heating brings unparalleled precision to commercial plant room installations at Marble House. Our dedicated team ensures reliable heating solutions tailored for businesses in this exclusive London location, ensuring peak performance year-round."
      keywords="plant room installation, plant room renovation, plant room commercial heating installs, plant room contractor, heating plant room london"
    />
     <div class="grey-segment text-left">
      <h1 class="heading-color">Plant Room Installation</h1>
      <h4 class="sub-heading pb-2">Marble House</h4>
      <Row>
        <Col lg>
          <Img
            fluid={data.newmarblehillhouse50.childImageSharp.fluid}
            className="pb-4 mx-auto d-block img-fluid"
            alt="BBIS-Portland-Place"
          />
          <h4 class="sub-heading pt-4">Project details</h4>
          <h5>Before</h5>
          <p>
          A dated boiler room installed in the 1970’s by the old GLC. Parts were obsolete for the ageing boilers and plant. The plant room was in dire need of modernisation.
          </p>
          <h5>After</h5>
          <p>The renovation project involved various tasks aimed at enhancing the boiler room. It includes repairing the existing floor, as well as painting the walls, floor, and ceiling to give the space an updated look. Additionally, the installation of 2 new high efficiency condensing boilers and stainless steel flues was carried out, ensuring improved efficiency and durability. The project also encompasses the installation of necessary pipework and fittings, guaranteeing a seamless and reliable system. To optimise functionality, automatic controls and a distribution panel were installed, enabling efficient management and regulation of the boiler room.
          </p>
          <StyledButton
            href="https://hdvirtualart.com/tours/bbis/002/"
            variant="primary"
            size="lg"
            target="_blank"
          >
            View 360 tour
          </StyledButton>
        </Col>
      </Row>
      <h4 class="sub-heading pt-4">Project images</h4>
        <br></br>
      <Row>

      <Col md className="pbs">
          <Img
            fluid={data.marblehouse.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>

        <Col md className="pbs">
          <Img
            fluid={data.marblehouse1.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.marblehouse2.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>

        <Col md className="pbs">
          <Img
            fluid={data.marblehouse3.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
      <br></br>
      <Row>
      <Col md className="pbs">
          <Img
            fluid={data.marblehouse4.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>

        <Col md className="pbs">
          <Img
            fluid={data.marblehouse5.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
        <Col md className="pbs">
          <Img
            fluid={data.marblehouse6.childImageSharp.fluid}
            alt="BBIS-Marble-House"
            className="pb-4 mx-auto d-block img-fluid"
          />
        </Col>
      </Row>
    </div>
  </Layout>
);

export const query = graphql`
  query MarbleImages {
    marblehouse: file(relativePath: { eq: "Projects/marblehouse/marblehouse.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    newmarblehillhouse50: file(relativePath: { eq: "Projects/marblehouse/newmarblehillhouse.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 945) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    marblehouse1: file(relativePath: { eq: "Projects/marblehouse/marblehouse1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse2: file(relativePath: { eq: "Projects/marblehouse/marblehouse2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse3: file(relativePath: { eq: "Projects/marblehouse/marblehouse3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse4: file(relativePath: { eq: "Projects/marblehouse/marblehouse4.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse5: file(relativePath: { eq: "Projects/marblehouse/marblehouse5.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    marblehouse6: file(relativePath: { eq: "Projects/marblehouse/marblehouse6.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 945) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
